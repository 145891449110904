#id_container_red {
    background-image: url('../../assets/FRANJA-ROJA-V2.png');
    width: 100%;
}

#id_container_red .cls_container_title {
    height: 18vh;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    font-weight: 600;
}

@media (max-width: 450px) {

    #id_container_red .cls_container_title {
        height: 7vh;
        font-size: 25px ;
    }
}