*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
}

button{
    border: none;
    border-radius: 10px;
    background: none;   
    font-family: var(--font-text);
}

img{
    width: 100%;
    height: 100%;
}
