#id_container_art_tecnologia {
    padding: 5% 15%;
}

#id_container_art_tecnologia .cls_container_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#id_container_art_tecnologia .cls_container_title h1 {
    color: var(--primaryTextColor);
    text-align: center;
    font-weight: 600;
    font-size: 2.5vw;
    padding: 0 10vw 2vh 10vw;
}

#id_container_art_tecnologia .cls_container_autor {
    display: flex;
    flex-direction: row;
}

#id_container_art_tecnologia .cls_container_autor img {
    width: 10vw;
}

#id_container_art_tecnologia .cls_info_author h4{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}

.anticon svg {
    transition: transform 0.5s ease;
}

.ant-list-item, .ant-list{
    flex-direction: column;
    align-items: flex-start !important;
    border-block-end: none !important;
    font-size: 1.2vw;
}

.cls_body_left .list-item-title{
    color: var(--primaryTextColor);
    text-decoration: underline;
    cursor: pointer;
}

.rotate-icon{
    font-weight: bold;
    font-size: 1.1vw;
}

#id_container_art_tecnologia .cls_btn_category {
    border: 2px solid var(--primaryTextColor);
    font-weight: 500;
    padding: 0 30px 0 30px;
    margin-left: 25px;
}

#id_container_art_tecnologia .cls_btn_category:hover {
    background-color: var(--primaryTextColor);

}

#id_container_art_tecnologia .cls_container_imgP {
    padding-top: 40px;
}

#id_container_art_tecnologia .cls_body_article {
    display: flex;
    padding-top: 4vh;
}

#id_container_art_tecnologia .cls_body_left {
    flex: 3;
    text-align: justify;
    margin-right: 2vw;
}

#id_container_art_tecnologia .cls_body_right {
    flex: 1;
}

#id_container_art_tecnologia .cls_body_right .cls_group_redes {
    display: flex;
    padding: 2vh 0;
}


#id_container_art_tecnologia .cls_body_right .cls_group_redes .cls_icon {
    color: var(--primaryTextColor);
    font-size: 25px;
}


@media (max-width: 450px) {
    .ant-list-item, .ant-list{
        flex-direction: column;
        align-items: flex-start !important;
        border-block-end: none !important;
        font-size: 4vw;
    }

    .rotate-icon{
        font-weight: bold;
        font-size: 4vw;
    }
    
    #id_container_art_tecnologia {
        padding: 5% 5%;
    }

    #id_container_art_tecnologia .cls_container_title h1 {
        font-size: 20px;
        padding: 0;
    }

    #id_container_art_tecnologia .cls_container_autor img {
        width: 90px;
    }

    #id_container_art_tecnologia .cls_info_author h4 {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        padding-top: 4vw;
    }

    #id_container_art_tecnologia .cls_body_article {
       flex-direction: column;
    }

    #id_container_art_tecnologia .cls_body_right {
        font-size: 18px;
        font-weight: 500;
     }
}