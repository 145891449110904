#id_suscribete {
    background-image: url('../../assets/fondo-2.png');
    display: flex;
    height: 60vh;
    width: 100%;
    padding: 5% 10% 0 15%;
}

#id_suscribete .cls_container_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
}

#id_suscribete .cls_container_body h2 {
    font-size: 2.5vw;
    font-weight: 600;
    color: var(--primaryTextColor);
    text-align: center;
    padding: 0 4vw;
}

#id_suscribete .cls_container_body h2 span {
    font-size: 2.5vw;
    font-weight: 400;
}

#id_suscribete .cls_cotainer_store {
    padding: 4vh 0;
    display: flex;
    justify-content: space-between;

}

#id_suscribete .cls_cotainer_store img {
    height: 7vh;
    padding: 0 10px;
}

#id_suscribete .cls_container_body button {
    // background-color: var(--primaryTextColor);
    // border-radius: 25px;
    // color: white;
    // font-size: 18px;
    // width: 10vw;
    // height: 5vh;
    margin-top: 5vh;
    background-color: var(--primaryTextColor);
    color: white;
    width: 15vw;
    height: 3vw;
    font-size: 1.3vw;
    line-height: 1.3vw;
    border-radius: 25px;
    min-width: 150px;
    min-height: 30px;
}

@media (max-width: 450px) {
    #id_suscribete {
        height: 100%;
        padding: 10% 0% 0 0%;
        flex-direction: column;
    }

    #id_suscribete .cls_container_body h2 {
        font-size: 20px;
        padding: 15px 35px;
    }

    #id_suscribete .cls_container_body h2 span {
        font-size: 20px;
    }

    #id_suscribete .cls_imagen {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    #id_suscribete .cls_imagen img {
        margin-top: 20px;
        height: 190px;
        width: 140px;
    }

    #id_suscribete .cls_container_body button {
        font-size: 17px;
    }

    #id_suscribete .cls_cotainer_store {
        height: 12vw;
    }

    #id_suscribete .cls_cotainer_store img {
        height: 12vw;
    }
}