// ::-webkit-scrollbar{
//   width: 8px;
//  //  background-color: #e6007d;
//  }

// ::-webkit-scrollbar-thumb{

//      background: #e6007d;
//      border-radius: 5px;
//     }


@media (max-width: 450px) {
    .carousel-root {
        width: 300px !important;
    }
}
.carousel-root {
    width: 20vw;
}

.carousel-status {
    visibility: hidden;
}

.carousel .control-dots .dot {
    background-color: var(--primaryTextColor) !important;
}

// .container-pulse {
//     outline: 0.1px solid;
//     outline-color: rgba(var(--background-color));
//     // animation: pulse-border   0.8s infinite;
//     mix-blend-mode: luminosity;
//   }

//   @keyframes pulse-border {
//     0% {
//       outline-offset: 0;
//       outline-color: rgb(255, 255, 255);    
//     }


//     100% {
//       outline-offset: 20px;
//       outline-color: rgba(255, 255, 255, 0);
//     }
//   }

//   @keyframes pulse-black {
//     0% {
//       transform: scale(0.95);
//       box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
//     }

//     70% {
//       transform: scale(1);
//       box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
//     }

//     100% {
//       transform: scale(0.95);
//       box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
//     }
//   }