#id_card_blog_body {
    margin: 4vh 0;
    // position: relative;
    height: 32vh;
    padding: 5px;
    display: flex;
    flex-direction: column;
    // max-height: ;
    width: 100%;
    cursor: pointer;
}

#id_card_blog_body:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


#id_card_blog_body img {
    height: 40%;
    padding-bottom: 1vh;
}

#id_card_blog_body h2 {
    font-size: 1vw;
    padding: 0 0;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

}

#id_card_blog_body p {
    font-size: 0.5vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
}

#id_card_blog_body h6 {
    font-size: 0.5vw;
    font-weight: 400;
    padding: 5px 0;
}

@media (max-width: 450px) {
    #id_card_blog_body img {
        height: 50%;
    }

    #id_card_blog_body h2 {
        font-size: 14px;
        line-height: 14px;
    }

    #id_card_blog_body p {
        font-size: 10px;
        line-height: 10px;
    }

    #id_card_blog_body h6 {
        font-size: 12px;
        line-height: 12px;
    }
}