#id_container_question .cls_question_body{
    margin: 5vw 20vw;
}

.cls_question_body .cls_question_list{
    color: var(--primaryTextColor);
    cursor: pointer;
    padding-left: 10px;
    width: 100%;
    border: 1.5px solid var(--primaryTextColor);
    border-radius: 10px;
    user-select: none;
}

.cls_question_center{
    margin-top: 25px;
}

.cls_question_button{
    margin-top: 20px;
    color:white;
    background: green;
    font-weight: 700;
    font-size: 15px;
    border: none;
}

.cls_question_button:active{
    font-size: 18px;
    color: white !important;
    border: none;
    transform: scale(1.05);
}

.cls_question_text_question{
    color: var(--primaryTextColor);
}

@media (max-width: 450px) {
    #id_container_question .cls_question_body{
        margin: 5vw 5vw;
    }
}