#id_container_page_cate {
    padding: 5% 15%;
}

#id_container_page_cate .cls_container_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#id_container_page_cate .cls_container_title h1 {
    color: var(--primaryTextColor);
    text-align: center;
    font-weight: 600;
    font-size: 4vw;
    padding: 0 10vw 2vh 10vw;
}

@media (max-width: 450px) {
    #id_container_page_cate {
        padding: 5%;
    }

    #id_container_page_cate .cls_container_title h1 {
        font-size: 24px;
    }
}