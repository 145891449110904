.cls-contain-body::-webkit-scrollbar {
  width: 8px;
  //  background-color: #e6007d;
}

.cls-contain-body::-webkit-scrollbar-thumb {

  background: #e6007d;
  border-radius: 5px;
}


.ant-message-notice-content {
  background-color: #e6007d !important;
  color: aliceblue;
}

:where(.css-dev-only-do-not-override-1nzfoqd).ant-form-item {
  margin-bottom: 0;
}

:where(.css-dev-only-do-not-override-1nzfoqd).ant-form-item .ant-form-item-label>label {
  font-size: 12px;
}

@media (max-width: 575px) {
  :where(.css-dev-only-do-not-override-1nzfoqd).ant-form-item .ant-form-item-label {
    padding: 0;
  }
}

:where(.css-dev-only-do-not-override-1nzfoqd).animate_button_scale:hover{
  transform: scale(1.05);
  transition: transform 1s ease-in-out;
}