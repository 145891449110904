#id_card_blog {
    margin: 4vh 0;
    position: relative;
}

#id_card_blog img {
    height: 70%;
    padding-bottom: 2vh;
}

#id_card_blog h2 {
    // font-size: 1vw;
    padding: 1.2vw 0;

}

#id_card_blog p {
    font-size: 1vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
}

#id_card_blog h6 {
    font-size: 1.1vw;
    font-weight: 400;
    padding: 5px 0;
}

@media (max-width: 450px) {
    #id_card_blog img {
        height: 50%;
    }

    #id_card_blog h2 {
        font-size: 14px;
        line-height: 14px;
    }

    #id_card_blog p {
        font-size: 10px;
        line-height: 10px;
    }

    #id_card_blog h6 {
        font-size: 12px;
        line-height: 12px;
    }
}
