#id_page_about #id_container_section_two {
    display: flex;
    align-items: center;
    padding: 5% 15%;
    background-image: url('../../assets/fondo-blanco.png');
}

#id_page_about #id_container_section_two_left {
    // padding-left: 8%;
    padding-right: 7%;
}

#id_page_about #id_container_section_two_right {
    // padding-left: 8%;
    padding-right: 10%;
    min-width: 150px;
}

#id_page_about #id_container_section_two_left h2 {
    font-size: 3vw;
    padding-bottom: 5%;
}

#id_page_about #id_container_section_two_left p {
    text-align: justify;
    font-size: 1.5vw;
    padding-bottom: 2vw;
}

/*************************THREE CONTAINER *******************************/

#id_page_about #id_container_section_three {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 15%;
    padding-bottom: 0;
    // background-color: #606060;
    background-image: url('../../assets/fondo3.png');
    text-align: center;
}

#id_page_about #id_container_section_three h2 {
    font-size: 2.8vw;
}

#id_page_about #id_container_section_three h4 {
    padding-top: 1%;
    font-size: 2vw;
    font-weight: 500;
}

#id_page_about #id_container_section_three .cls_content {
    position: relative;
}

#id_page_about #id_container_section_three .cls_content {
    display: flex;
    width: 100%;
    padding: 0 15%;
    padding-top: 10%;
}

#id_page_about #id_container_section_three #id_content_options {
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transform: translateY(12%);
}

#id_page_about #id_container_section_three #id_content_options button {
    background-color: transparent;
    color: white;
    margin-bottom: 3vh;
    font-size: 1.8vw;
    font-weight: 400;
    height: auto;
    box-sizing: border-box;
    width: min-content;
    border-radius: 50px;
}

#id_page_about #id_container_section_three #id_content_options button:hover {
    background-color: #991319;
    border-color: #991319;
}




//**************************************SECTION FOUR *************************************/
#id_page_about #id_container_section_four {
    // display: flex;
    width: 100%;
    padding: 0 20%;
    background-image: url('../../assets/fondo-blanco.png');

    // padding-top: 10%;
}

#id_page_about #id_container_section_four h3 {
    text-align: center;
    padding: 6vh 0;
    font-size: 2.2vw;
    color: var(--primaryTextColor);
}

#id_page_about #id_container_section_four .cls_container_body {
    display: flex;
    padding-bottom: 6vw;
}

#id_page_about #id_container_section_four .cls_item_list {
    font-size: 1.5vw;
    padding: .8vw 0;
}

#id_page_about #id_container_section_four .cls_letters_slider {
    font-size: 1.3vw;
    font-weight: 500;
    padding-top: 10px;
}

#id_page_about #id_container_section_four .cls_flex_left {
    flex: 2;
}

#id_page_about #id_container_section_four .cls_flex_right {
    flex: 1;
}



#id_page_about .cls_card_slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15% 20% 15%;
    align-items: center;
    // padding-top: 10%;
}

#id_page_about .cls_card_slider .cls_card_img {
    width: 12vw;
    height: 12vw;
    min-width: 150px;
    min-height: 150px;
}

#id_page_about .cls_card_slider .cls_card_slider h5 {
    font-size: 3vw;
}


#id_page_about #id_container_section_five {
    // display: flex;
    width: 100%;
    padding: 3.5% 20% 2% 20%;
    background-image: url('../../assets/FRANJA-ROJA-grande-V2.png');

    // padding-top: 10%;
}

#id_page_about #id_container_section_five {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#id_page_about #id_container_section_five #id_title_section_five {
    color: white;
    text-align: center;
    font-size: 2vw;
    font-weight: 600;
    // padding-top: 10%;
}

#id_page_about #id_container_section_five #id_title_section_five span {
    color: white;
    text-align: center;
    font-size: 1.5vw;
    font-weight: 600;
    // padding-top: 10%;
}

#id_page_about #id_container_section_five button {
    width: 15vw;
    height: 3vw;
    border-radius: 25px;
    margin: 2vw 0;
    min-width: 170px;
    min-height: 30px;
    margin-top: 20px;
}

#id_page_about #id_container_section_five button span {
    font-size: 1.3vw;
    font-weight: 700;
}

#id_page_about #id_container_section_five:hover button span {
    color: var(--primaryTextColor);
}



#id_page_about #id_container_section_six {
    // display: flex;
    width: 100%;
    padding: 0 20%;
    background-image: url('../../assets/fondo-blanco.png');

    // padding-top: 10%;
}

#id_page_about #id_container_section_six h3 {
    text-align: center;
    padding: 6vh 0;
    font-size: 2.2vw;
    color: var(--primaryTextColor);
}

#id_page_about #id_container_section_six .cls_container_body {
    display: flex;
    padding-bottom: 6vw;
}

#id_page_about #id_container_section_six .cls_item_list {
    font-size: 1.5vw;
    padding: .8vw 0;
    text-align: justify;
}

#id_page_about #id_container_section_six .cls_letters_slider {
    font-size: 1.3vw;
    font-weight: 500;
    padding-top: 10px;

}

#id_page_about .cls_card_slider .cls_card_img_med {
    width: 12vw;
    height: 12vw;
    min-width: 150px;
    min-height: 150px;
}


@media (max-width: 450px) {
    #id_page_about #id_container_section_three{
        padding: 5% 0%;
        padding-bottom: 0;
    }

    #id_page_about #id_container_section_three #id_content_options button{
        font-size: 3vw;
    }

    #id_page_about #id_container_section_three h2 {
        font-size: 3.8vw;
    }

    #id_page_about #id_container_section_three .cls_content{
        padding: 0 5%;
        padding-top: 5%;
    }

    #id_page_about #id_container_section_three #id_content_options {
        transform: translateY(5%);
    }

    #id_page_about #id_container_section_three h4{
        font-size: 3vw;
    }

    #id_page_about #id_container_section_two {
        padding: 5% 5% 5% 7%;
    }

    #id_page_about #id_container_section_two_left {
        padding: 0;
    }

    #id_page_about #id_container_section_two_right {
        padding: 0;
        display: flex;
        justify-content: flex-end;
    }

    #id_page_about #id_container_section_two_right img {
        height: 200px;
        width: 80%;
    }

    #id_page_about #id_container_section_two_left h2 {
        font-size: 16px;
        line-height: 16px;
    }

    #id_page_about #id_container_section_two_left p {
        font-size: 10px;
        line-height: 10px;
    }

    #id_page_about #id_container_section_four h3 {
        font-size: 20px;
        padding: 5% 0;
    }

    #id_page_about #id_container_section_four {
        padding: 5% 10%;
    }

    #id_page_about #id_container_section_four .cls_container_body {
        flex-direction: column;
    }

    #id_page_about #id_container_section_four .cls_item_list {
        font-size: 15px
    }

    #id_page_about #id_container_section_four .cls_flex_right {
        display: flex;
        justify-content: center;
    }
    #id_page_about #id_container_section_four .cls_letters_slider {
        font-size: 15px
    }

    #id_page_about #id_container_section_five{
        padding: 5% 5%;  
    }
    #id_page_about #id_container_section_five #id_title_section_five {
        font-size: 18px;
    }
    #id_page_about #id_container_section_five #id_title_section_five span {
        font-size: 15px;
    }

    
    #id_page_about #id_container_section_five button span {
        font-size: 16px;
        // font-weight: 700;
    }

    #id_page_about #id_container_section_six h3 {
        font-size: 20px;
        padding: 5% 0;
        color: var(--primaryTextColor);
    }

    #id_page_about #id_container_section_six {
        padding: 5% 10%;
    }

    #id_page_about #id_container_section_six .cls_container_body {
        flex-direction: column;
    }

    #id_page_about #id_container_section_six .cls_item_list {
        font-size: 15px;
    }

    #id_page_about #id_container_section_six .cls_flex_left {
        display: flex;
        justify-content: center;
    }

    #id_page_about #id_container_section_six .cls_letters_slider {
        font-size: 15px;
    }

}