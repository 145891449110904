#id_container_blog {
    padding: 5% 30%;
}

#id_container_blog .cls_container_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#id_container_blog .cls_container_title h1 {
    color: var(--primaryTextColor);
    text-align: center;
    font-weight: 600;
    font-size: 2vw;
    padding-bottom: 2vh;
}

#id_container_blog .cls_container_title .cls_link {
    color: #6ba0e0;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

#id_container_blog .cls_container_title .cls_group_btn {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 4vh;
}

#id_container_blog .cls_container_title .cls_group_btn button {
    margin: 0 .5vw;
    font-weight: 500;
}

#id_container_blog .cls_container_title .cls_group_btn button:hover {
    background-color: var(--primaryTextColor);
    color: white;
}


#id_container_blog .cls_container_body_blog{
    display: flex;
}


@media (max-width: 450px) {

    #id_container_blog {
        padding: 5%;
    }

    #id_container_blog .cls_container_title h1 {
        font-size: 20px;
    }
    
    #id_container_blog .cls_container_title p {
        text-align: center;
    }

}