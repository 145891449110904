#id_header {
  /* background-color: var(--primaryTextColor); */
  height: 70px;
  padding: 0 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#id_header img {
  height: 5vw;
  width: 12vw;
}

#id_header  .cls_menu_header {
  display: flex;
  align-items: center;
}

#id_header .cls_option_menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#id_header .cls_container_logo {
  display: flex;
  align-items: flex-end;
}
#id_header .cls_option_menu h4 {
  text-decoration: underline;
  color: white;
  padding: 10px 0;
  font-size: .8vw;
  font-weight: 400;
}

#id_header .cls_option_menu button {
  border-radius: 25px;
  /* height: 25px; */
  /* line-height: 20px; */
}

#id_header .cls_option_menu:hover button span {
  color: var(--primaryTextColor);
}

@media (max-width: 450px) {
  #id_header {
    height: 60px;
    padding: 0 10%;
  }
  #id_header img {
    height: 7vw;
    width: 30vw;
  }

  #id_header .cls_option_menu{
    visibility: hidden;
  }
}
