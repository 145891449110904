#id_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#id_form .cls_style_check {
  font-size: 15px;
  line-height: 15px;
}

#id_form .cls_btn_submit {
  background-color: var(--primaryTextColor);
  color: white;
  margin: 20px 0;
}

@media (max-width: 450px) {
  #id_form .cls_style_check {
    font-size: 12px;
    line-height: 12px;
  }

  #id_form .cls_btn_submit {
    background-color: var(--primaryTextColor);
    color: white;
  }
}
