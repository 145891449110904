.cls_container_menu {
  display: flex;
  flex-direction: column;
}

.cls_container_menu button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 1.5vw;
  text-align: inherit;

  // padding: 2vw 0;
}



.cls_container_menu button:hover span {
  background-color: transparent;
  color: black;
  text-align: inherit;

}


.menu {
  // outline: 3px solid #369;
  // clip-path: polygon(0 0, 80% 0, 100% 20%, 100% 100%, 20% 100%, 0 80%);

  /* outline-style: solid;
    outline-width: 10px; padding: 15px;  */
  // background-color: #000000 ;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 32px;
  padding-left: 15px;
  margin: 0;
  position: relative;
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.menu .line {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 2px;
  background-color: white;
  transition: transform 0.5s ease-in-out, opacity 2s, width 0.5s;
  /* transition: width 0.5s ease-in-out, height 0.5s ease-in-out; */
}


.menu .line1 {
  transform: translateY(-5px);
}

.menu .line3 {
  transform: translateY(5px);
}

.menu.open .line1 {
  transform: rotate(45deg) translate(1px, 1px);
}

.menu.open .line2 {
  transition: opacity 0.5s;
  opacity: 0;
}

.menu.open .line3 {
  transform: rotate(-45deg) translate(1px, -1px);
}

.menu:hover .line1,
.menu:hover .line3 {
  width: 20px;
}

.menu:hover .line2 {
  width: 25px;
}

#id_menu_interior .cls_option_menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
}

#id_menu_interior .cls_option_menu h4 {
  text-decoration: underline;
  color: var(--primaryTextColor);
  padding: 10px 0;
  font-size: 1vw;
  font-weight: 400;
  cursor: pointer;
}

#id_menu_interior .cls_option_menu button {
  border-radius: 25px;
  height: 35px;
  line-height: 20px;
  background-color: var(--primaryTextColor);
  color: white;
}

#id_menu_interior .cls_option_menu:hover button span {
  color: white;
}

@media (max-width: 450px) {
  .cls_container_menu button {
    font-size: 18px;
  }
  #id_menu_interior .cls_option_menu {
    visibility: visible;
  }

  #id_menu_interior .cls_option_menu h4 {
    font-size: 15px;
  }
  
  :where(.css-dev-only-do-not-override-1nzfoqd).ant-drawer .ant-drawer-content {
    width: 80%;
    right: 0 !important;
  }

  :where(.css-dev-only-do-not-override-1nzfoqd).ant-drawer-right>.ant-drawer-content-wrapper {
    left: 25% !important;
  }


}