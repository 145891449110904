#id_card_service {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 15px;
    cursor: pointer;
}
    
#id_card_service img {
    width: 12em;
}

#id_card_service img:hover {
    width: 12em;
    transform: scale(1.1);
    transition: transform 1s ease-in-out;
}

#id_card_service .cls_container_title {
    padding: 0 5%;
    text-align: center;
    overflow: hidden;
    display: flex;

}

#id_card_service .cls_container_title span {
   margin-top: -22px ;

}

#id_card_service h2 {
    font-weight: 500;
    padding: 2vw 0;
}

@media (max-width: 450px) {
    #id_card_service {
        width: 40%;
        height: 170px;
        margin: 10%;
        padding: 0;
        margin: 10px;
    }

    #id_card_service img {
        height: 90px;
        width: 100px;
    }
    
    #id_card_service h2 {
        font-size: 17px;
    }
    #id_card_service .cls_container_title span {
        margin-top: -0 ;
        font-size: 11px;
     }
     
}