@font-face {
  font-family: "Gotham Rounded";
  src: url("../../assets/fonts/GothamRounded/GothamRoundedBold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../../assets/fonts/GothamRounded/GothamRoundedMedium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../../assets/fonts/GothamRounded/GothamRoundedLight.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/Gotham-Font/Gotham-Black.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/Gotham-Font/Gotham-Bold.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/Gotham-Font/GothamMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/Gotham-Font/Gotham-Light.otf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/Gotham-Font/Gotham-XLight.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets/fonts/Gotham-Font/Gotham-Thin.otf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

:root {
  // fonts
  --font-title: "Gotham Rounded";
  --font-gothan: "Gotham";
  // colores
  --backgroundColor: white;
  --primaryTextColor: rgb(176,19,25);
  --secundaryTextColor: #545454;
  // size letra
  // --text-mobil:"17px"
  // --text-table:"20px"
  // --text-descktop:"20px"
}