.video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
  
#id_container_home .cls_container_section_one{
    position: absolute;
    z-index: 1;
}

#id_container_home .cls_container_one_video{
    position: relative;
}

#id_container_home .cls_logo_one{
    width: 16vw;
    height: 3vw;
    min-width: 150px;
    min-height: 30px;
    cursor: pointer;
}

#id_container_home .cls_container_title {
    display: flex;
    flex-direction: column;
    padding: 8vw 45% 0 21%;
}

#id_container_home .cls_header {
    display: flex;
    justify-content: space-between;
    padding: 2.2% 20%;
}

#id_container_home .cls_container_logo {
    display: flex;
    align-items: flex-end;
}

#id_container_home .cls_header .cls_menu_header {
    display: flex;
    align-items: center;
}

#id_container_home .cls_header .cls_option_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#id_container_home .cls_header .cls_option_menu h4 {
    text-decoration: underline;
    color: white;
    padding: 10px 0;
    font-size: .8vw;
    font-weight: 400;
}

#id_container_home .cls_container_title h1 {
    font-size: 3vw;
    font-weight: 600;
    color: var(--primaryTextColor);
}

#id_container_home .cls_container_title h1 span {
    font-size: 3vw;


    color: var(--primaryTextColor);
}

#id_container_home .cls_container_title h4 {
    font-size: 1.5vw;
    color: var(--secundaryTextColor);
    font-weight: 500;
    padding: 4vh 0;
}

#id_container_home .cls_container_title button {
    margin-top: 1vh;
    background-color: var(--primaryTextColor);
    color: white;
    width: 15vw;
    height: 3vw;
    font-size: 1.3vw;
    line-height: 1.3vw;
    border-radius: 25px;
    min-width: 160px;
    min-height: 30px;
    border: none;
}

#id_container_home .cls_container_title button:hover {
    background-color: var(--primaryTextColor);
}

#id_container_home .cls_container_section_two {
    // height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 15%;
}

#id_container_home .cls_sub_title {
    text-align: center;
    font-size: 2vw;
    color: var(--primaryTextColor);
    padding: 0 0 8vh 0;

}

#id_container_home .cls_sub_title span {
    color: var(--primaryTextColor);
    font-weight: 500;
}

#id_container_home .cls_container_service {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100%;
}

#id_container_home .cls_group_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

#id_container_home .cls_container_service button {
    margin-top: 8vh;
    background-color: var(--primaryTextColor);
    color: white;
    width: 15vw;
    height: 3vw;
    font-size: 1.3vw;
    line-height: 1.3vw;
    border-radius: 25px;
    min-width: 150px;
    min-height: 30px;
}

#id_container_home .cls_container_subTitle {
    background-image: url('../../assets/FRANJA-ROJA-V2.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 28%;
}

#id_container_home .cls_container_subTitle img {
    width: 6vw;
    padding-bottom: 4vh;
}

#id_container_home .cls_container_subTitle h2 {
    color: white;
    font-size: 2vw;
    line-height: 2vw;
    text-align: center;
    font-weight: 600;
}

#id_container_home .cls_container_subTitle h2 span {
    font-weight: 400;
}

#id_container_home .cls_container_que_nos_hace_diferent {
    display: flex;
    justify-content: center;
    padding: 3% 15% 0 15%;
    height: 80vh;
}

#id_container_home .cls_container_collapse {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

#id_container_home .cls_container_collapse h3 {
    color: var(--primaryTextColor);
    font-size: 1.7vw;
}

#id_container_home .cls_title_collapse span {
    color: var(--secundaryTextColor);
    font-size: 1.5vw;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

#id_container_home .active span {
    color: var(--primaryTextColor)
}

#id_container_home .cls_title_collapse:hover span {
    color: var(--primaryTextColor);
    font-size: 1.5vw;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

#id_container_home .cls_container_beneficios {
    background-image: url('../../assets/FRANJA-ROJA-V2.png');
    display: flex;
    flex-direction: column;
    padding: 5% 20%;
    align-items: center;
    // height: 40vh;
}

#id_container_home .cls_container_beneficios h2 {
    color: white;
    font-weight: 500;
    font-size: 2vw;
    padding: 0 0 4vw 0;
}

#id_container_home .cls_container_beneficios h2 span {
    color: white;
    font-weight: 400;
}


#id_container_home .cls_container_icons {
    display: flex;
    // height: 50vh;
}

#id_container_home .cls_container_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#id_container_home .cls_container_icon img {
    width: 5vw;
    transition: transform 0.3s;
}

#id_container_home .cls_container_icon img:hover {
    transform: scale(1.2);
}


#id_container_home .cls_container_icon h4 {
    color: white;
    font-weight: 500;
    text-align: center;
    padding: 1vw 2vw;
    font-size: 1.15vw;
}



@media (max-width: 450px) {

    #id_container_home .cls_header {
        padding: 2vh 5%;
    }

    #id_container_home .cls_container_logo {
        align-items: center;
    }
    #id_container_home {
        width: 100%;
    }

    #id_container_home .cls_container_section_one {
        background-position: 45% 5%;
        height: 80vh;
    }

    #id_container_home .cls_container_title {
        padding: 90% 10% 0 10%;
    }

    #id_container_home .cls_container_title h1 {
        font-size: 35px;
        text-shadow: 0px 0px 15px white;
    }

    #id_container_home .cls_container_title h1 span {
        font-size: 25px;
    }

    #id_container_home .cls_container_title h4 {
        font-size: 20px;
    }

    #id_container_home .cls_container_title button {
        font-size: 17px;
    }

    #id_container_home .cls_container_section_two {
        height: 100%;
        padding: 6vh 10% 7vh 10%;
    }

    #id_container_home .cls_sub_title {
        font-size: 20px;
    }

    #id_container_home .cls_container_service button {
        font-size: 15px;

    }

    #id_container_home .cls_container_subTitle {
        padding: 5% 10%;
    }

    #id_container_home .cls_container_subTitle img {
        width: 10vw;
        padding-bottom: 1vh;
    }

    #id_container_home .cls_container_subTitle h2 {
        font-size: 16px;
        line-height: 16px;
    }

    #id_container_home .cls_container_que_nos_hace_diferent {
        flex-direction: column-reverse;
        height: 100%;
        padding: 10vh 10% 0 10%;
    }

    #id_container_home .cls_container_collapse {
        width: 100%;
    }

    #id_container_home .cls_container_collapse h3 {
        font-size: 20px;
    }

    #id_container_home .cls_title_collapse span {
        font-size: 15px;
        margin: 0;
        padding: 0;
    }

    #id_container_home .cls_title_collapse:hover span {
        font-size: 15px;
        margin: 0;
        padding: 0;
    }

    #id_container_home .cls_container_beneficios {
        padding: 5% 10%;
        height: 100%;
    }

    #id_container_home .cls_container_beneficios h2 {
        text-align: center;
        padding: 20px 0px;
        font-size: 20px;
    }

    #id_container_home .cls_container_icons {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    #id_container_home .cls_container_icons h4 {
        font-size: 16px;
    }

    #id_container_home .cls_container_icon {
        flex-wrap: 1;
        width: 50%;
    }


    #id_container_home .cls_container_icon img {
        width: 30%;
        padding: 20px 0;
    }

    #id_container_home .cls_option_menu {
        visibility: hidden;
    }

}




//  extras
.ant-collapse-header {
    padding: 10px 0 !important;
    font-size: 18px !important;
}

.ant-collapse-content-box p {
    text-align: justify !important;
}

:where(.css-dev-only-do-not-override-1nzfoqd).ant-collapse>.ant-collapse-item>.ant-collapse-header {
    align-items: center;
}

:where(.css-dev-only-do-not-override-1nzfoqd).ant-collapse>.ant-collapse-item {
    border-bottom: 0
}