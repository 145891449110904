.cls_btn_suscribete {
    // margin-top: 8vh;
    background-color: var(--primaryTextColor);
    color: white;
    width: 10vw;
    height: 2vw;
    font-size: 1vw;
    line-height: 1.1vw;
    border-radius: 25px;
    min-width: 110px;
    min-height: 25px;
    border: none;
}

@media (max-width: 450px) {
    .cls_btn_suscribete {
        font-size: 10px;
        margin-top: 25px;

    }


}