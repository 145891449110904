.cls_custom_modal p{
    font-size: 1.35vw;
}

.cls_custom_modal .cls_modal_title{
    color: #991319;
    text-align: center;
    font-size: 3vw;
}

.cls_custom_modal .cls_custom_modal_body{
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.cls_custom_modal .cls_custom_modal_body_image{
    width: 35%;
    height: 100%;
    padding: 0 5%;
    margin-left: 5%;
}

.cls_custom_modal .cls_custom_modal_body_image img{
    width: 100%;
    height: 100%;
    // object-fit: cover;
}

.cls_custom_modal .cls_custom_modal_body_content{
    width:55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 450px) {
    .cls_custom_modal .cls_modal_title{
        font-size: 5vw;
    }
    .cls_custom_modal p{
        font-size: 2.5vw;
    }
    .cls_custom_modal .cls_custom_modal_body_image{
        width: 100%;
        height: 25vh;
        padding: 0 0 10% 0;
        margin-left: 0;
        // padding: 0 5%;
        // margin-left: 5%;
    } 
    .cls_custom_modal .cls_custom_modal_body_content{
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }      
}