#id_footer {
  background-color: #333333;
  height: 40vh;
  display: flex;
  padding: 2% 10% 1% 10%;
  flex-direction: column;
  justify-content: flex-end;
}

#id_footer .cls_container_column {
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#id_footer .cls_container_column .cls_container_column01 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#id_footer .cls_container_column .cls_container_column01 img {
  width: 6vw;
  height: 6vw;
  cursor: pointer;
}

#id_footer .cls_container_column .cls_container_column02 {
  flex: 1;
}

#id_footer .cls_container_column .cls_container_column02 h5 {
  font-family: var(--font-gothan);
  color: white;
  font-weight: 200;
  font-size: 1vw;
  line-height: 1.5vw;
}

#id_footer .cls_container_column .cls_container_column03 {
  flex: 1.3;
}

#id_footer .cls_container_column .cls_container_column03 h5 {
  font-family: var(--font-gothan);
  color: white;
  font-weight: 200;
  font-size: 1vw;
  line-height: 1.5vw;
}

#id_footer .cls_container_column .cls_container_column04 {
  flex: 1;
}

#id_footer .cls_contain_store img {
  padding: 5%;
  width: 40%;
  height: 50px;
}

#id_footer .cls_contain_redes button {
  background-color: #333333;
  border: none;
  color: white;
  font-size: 1.5vw;
}

#id_footer .cls_group_redes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
}

#id_footer .cls_btn_wsp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

}

#id_footer .cls_btn_wsp .cls_icon {
  color: white;
  width: 25px;
}

#id_footer .cls_btn_wsp span {
  color: white;
  padding-left: 10px;
  font-weight: 500;
}


#id_footer .cls_group_redes .cls_icon {
  color: white;
  width: 20px;
  margin: 0 5px;
}

#id_footer .cls_derechos {
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: 300;
}

#id_footer .cls_cotainer_store {
  padding: 1vh 0;
  display: flex;
  justify-content: space-between;

}

#id_footer .cls_cotainer_store img {
  height: 4vw;
  width: 8vw;
  padding: 0;
  // padding: 0 10px;
}

#id_footer #id_title_contact {
  font-size: 1.2vw;
  color: var(--primaryTextColor);
  font-weight: 600
}

@media (max-width: 450px) {
  #id_footer {
    height: 90vh;
  }

  #id_footer .cls_container_column {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
  }

  #id_footer .cls_container_column .cls_container_column01 {
    flex: 0;
    padding: 3vh 0 2vh 0;
  }

  #id_footer .cls_container_column .cls_container_column02 {
    padding: 2vh 0;
    flex: 0;
  }

  #id_footer .cls_container_column .cls_container_column03 {
    padding: 2vh 0;
    flex: 0;
  }

  #id_footer .cls_container_column .cls_container_column04 {
    padding: 2vh 0;
    flex: 0;
  }


  #id_footer .cls_container_column .cls_container_column01 img {
    height: 102px;
    width: 110px;
  }

  #id_footer .cls_contain_store img {
    padding: 0 5%;
    width: 50%;
    height: 60px;
  }

  #id_footer .cls_container_column .cls_container_column02 h5 {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 5px 0;
  }

  // #id_footer .cls_container_column .cls_container_column03 {
  //   flex: 1;
  // }

  #id_footer .cls_container_column .cls_container_column03 h5 {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    padding: 5px 0;
  }

  #id_footer .cls_contain_redes {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  #id_footer .cls_contain_redes button {
    font-size: 20px;
    margin: 10px 0;
  }

  #id_footer .cls_contain_store h5 {
    text-align: center;
    font-size: 20px;
  }
  #id_footer #id_title_contact {
    font-size: 15px;
  }

}