// #id_container_contact {}

#id_container_contact .cls_container_section {
    display: flex;
    padding: 2% 15%;
}

#id_container_contact .cls_form_left {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5%;
}

#id_container_contact .cls_form_left h2 {
    font-size: 2.5vw;
    padding-bottom: 2vh;
    text-align: justify;
}

#id_container_contact .cls_form_left h2 span {
    font-size: 2.5vw;
    color: var(--primaryTextColor);
}

#id_container_contact .cls_form_left h6 {
    font-size: 1.5vw;
    font-weight: 400;
    padding-bottom: 2vh;
    text-align: justify;

}

#id_container_contact .cls_form_left p {
    padding-top: 4.5vh;
    font-size: .6vw;
    font-weight: 400;
    text-align: justify;

}

#id_container_contact .cls_container_form {
    border: 2px solid var(--secundaryTextColor);
    border-radius: 25px;
    padding: 20px;
}

#id_container_contact .cls_form_right {
    flex: 2.5;
}

#id_container_contact .cls_container_title {
    height: 24vh;
    width: 100%;
    background-color: var(--secundaryTextColor);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#id_container_contact .cls_container_title h2 {
    text-align: center;
    font-size: 2.2vw;
    font-weight: 500;
    padding: 0 15%;
}


#id_container_contact .cls_container_video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 60vw;
    height: 80vh;
}

#id_container_contact .cls_container_video h2 {
    text-align: center;
    color: var(--primaryTextColor);
    padding: 4vw 20%;
    font-size: 2vw;
    font-weight: 600;
}

#id_container_contact .cls_style_movie {
    border: 5px solid white;
    border-radius: 0;
    box-shadow: -11px 16px 20px rgba(0, 0, 0, 0.5);
}

@media (max-width: 450px) {

    #id_container_contact .cls_container_section {
        display: flex;
        flex-direction: column;
        padding: 2% 10%;
    }

    #id_container_contact .cls_style_movie {
        width: 70%;
        height: 150px;
    }

    #id_container_contact .cls_container_title {
        height: 17vh;
        width: 100%;
    }

    #id_container_contact .cls_container_title h2 {
        font-size: 18px;
        font-weight: 500;
    }

    #id_container_contact .cls_container_video {
        width: 100%;
        height: 50vh;
    }

    #id_container_contact .cls_container_video h2 {
        font-size: 17px;
    }

    #id_container_contact .cls_form_left h2 {
        font-size: 25px;
        text-align: center;
    }
    #id_container_contact .cls_form_left h2 span {
        font-size: 25px;
        color: var(--primaryTextColor);
    }


    #id_container_contact .cls_form_left h6 {
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
    }

    #id_container_contact .cls_form_left p {
        text-align: justify;
        padding: 0 0 20px 0;
        font-size: 10px;
        font-weight: 400;
    }

}